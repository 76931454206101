// src/components/PrivacySection.tsx

import React from 'react';
import * as styles from './PrivacySection.module.css';

type PrivacySectionProps = {
  companyName: string;
};

const PrivacySection: React.FC<PrivacySectionProps> = ({ companyName }) => {
  return (
    <section className={styles.PrivacySection}>
      <h1>個人情報保護方針</h1>
      <p>
        {companyName}
        （以下、「当社」といいます）は、お客様の個人情報の重要性を深く認識し、個人情報の保護に関する法律及び関連法令を遵守し、個人情報の適切な取り扱いと保護に努めます。
      </p>
      <h2>第１．個人情報の定義</h2>
      <p>
        当社が取り扱う「個人情報」とは、お客様に関する情報であり、氏名、住所、電話番号、メールアドレスなど、特定の個人を識別できる情報を指します。
      </p>
      <h2>第２．個人情報の収集と利用目的</h2>
      <p>当社は、以下の目的のために個人情報を収集し、これを利用します。</p>
      <ol>
        <li>当社サービスを提供するため</li>
        <li>
          当社のサービスの改善向上、開発、情報提供のため
          利用者への料金請求のため
        </li>
        <li>利用者への商品の発送のため</li>
        <li>
          利用者へパンフレット、ダイレクトメール、メールマガジン等を送付・送信するため
        </li>
        <li>当社又は当社と連携する第三者の広告を行うため</li>
        <li>利用者からのお問い合わせ対応のため</li>
        <li>その他当社が別途事前に明示した目的で利用するため</li>
        <li>前各号の利用目的に付随する目的のため</li>
      </ol>
      <h2>第３．個人情報の第三者への提供</h2>
      <p>
        当社は、個人情報保護法その他の法令に基づき開示が認められる場合を除き、お客様の同意なく個人情報を第三者に提供することはありません。
      </p>
      <h2>第４．個人情報の管理</h2>
      <p>
        当社は、個人情報の安全管理を徹底するため、適切な安全対策を講じ、情報の漏洩、紛失、改ざんを防止します。
      </p>
      <h2>第５．個人情報の開示・訂正・削除</h2>
      <p>
        利用者は、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正、追加又は削除（以下「訂正等」といいます。）を請求することができます。当社は、利用者の請求に応じる必要があると判断した場合には、個人情報保護法等に基づき、遅滞なく、当該個人情報の訂正等を行うものとします。
      </p>
      <h2>第6. Cookie（クッキー）その他の技術の利用</h2>
      <p>
        （１）
        当社のサービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当社による当社のサービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、当社のサービスの一部の機能をご利用いただけなくなる場合があります。
      </p>
      <p>
        （２）
        当社は、当社サービスが提供するサービスの利用状況等を調査・分析するため、本サービス上に
        Google LLCが提供する
        Googleアナリティクスを利用しています。Googleアナリティクスでデータが収集、処理される仕組みその他Googleアナリティクスの詳しい情報につきましては、同社のサイトをご覧ください。
      </p>
      Google アナリティクス 利用規約：
      <br />
      <a href="https://www.google.com/analytics/terms/jp.html">
        https://www.google.com/analytics/terms/jp.html
      </a>
      <h4>
        お客様がGoogleパートナーのサイトやアプリを使用する際のGoogleによるデータ使用：
      </h4>
      <a href="https://www.google.com/intl/ja/policies/privacy/partners/">
        https://www.google.com/intl/ja/policies/privacy/partners/
      </a>
      <h4>Googleプライバシーポリシー：</h4>
      <a href="http://www.google.com/intl/ja/policies/privacy/">
        http://www.google.com/intl/ja/policies/privacy
      </a>
      <br />
      なお、お客様はご自身のデータが
      Googleアナリティクスで使用されることを望まない場合は、Google
      社の提供するGoogleアナリティクス オプトアウト アドオンをご利用ください。
      <br />
      <h4>Googleアナリティクス オプトアウト アドオン：</h4>
      <a href="https://tools.google.com/dlpage/gaoptout">
        https://tools.google.com/dlpage/gaoptout
      </a>
      <br />
      <h2>第7. お問い合わせ</h2>
      <p>
        開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、お問い合わせフォームよりお問い合わせください。
      </p>
      <h2>第8. 継続的改善</h2>
      <p>
        当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。変更後の方針については、当社のウェブサイト上で通知します。
      </p>
    </section>
  );
};

export default PrivacySection;
